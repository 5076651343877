<template>
  <main class="pb-6 bg-white">
    <div class="relative red-gray-800">
      <div class="absolute inset-0">
        <img class="hidden lg:block object-cover object-bottom w-full h-full" src="../assets/onderdelen_header.webp" width="1280" height="675" alt="machines_en_onderdelen">
        <img class="block lg:hidden object-cover object-bottom w-full h-full" src="../assets/onderdelen_header_small.webp" width="320" height="196" alt="machines_en_onderdelen">
        <div class="absolute inset-0 bg-gray-800" style="mix-blend-mode: hard-light;" aria-hidden="true"></div>
      </div>
      <div class="relative py-24 px-4 mx-auto max-w-7xl sm:py-32 sm:px-6 lg:px-8">
        <h2 class="text-4xl font-medium tracking-tight text-white sm:text-5xl lg:text-6xl ">Machines en onderdelen</h2>
        <p class="mt-6 max-w-3xl text-base text-gray-300 ">
          JC las en montagetechniek is gespecialiseerd in het monteren en vervaardigen van machines en onderdelen.
          Dit doen we geheel volgens uw wensen en specificaties.</p>
      </div>
    </div>

    <div class="overflow-hidden py-16">
      <div class="px-4 mx-auto space-y-8 max-w-7xl sm:px-6 lg:px-8">
        <div class="mx-auto max-w-prose text-base lg:max-w-none">
          <h2 class="mt-2 text-2xl font-semibold tracking-tight leading-8 text-gray-900 uppercase sm:text-2xl ">Machinebouw en montage</h2>
        </div>
        <div class="relative z-10 mx-auto max-w-prose text-base lg:max-w-5xl lg:mx-0 lg:pr-72">
          <p class="text-gray-600 text-md ">
            JC las en montagetechniek is gespecialiseerd in het monteren en vervaardigen van machines en onderdelen.
            Dit doen we geheel volgens uw wensen en specificaties.
            Het lassen van onderdelen voor uw machines of constructieonderdelen valt ook onder ons specialisme.
            Of die onderdelen nu van staal aluminium of RVS zijn. </p>
        </div>
        <div class="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
          <div class="relative z-10">
            <div class="mx-auto text-gray-600 prose prose-indigo lg:max-w-none">
              <p class="">Alle machines en onderdelen die wij bouwen zijn geheel volgens tekening en instructie vervaardigd. Wij zijn experts in het bouwen van machines en onderdelen en zullen daarbij altijd rekening houden met uw wensen en eisen.</p>

              <h2 class="mt-2 text-3xl font-bold tracking-tight leading-8 text-gray-900 uppercase sm:text-2xl ">Machine onderdelen laten maken</h2>
              <p class="text-gray-600 text-md ">
                Meer en meer bedrijven besteden het onderhoud en monteren van machines uit.
                Het liefst aan een leverancier. Daarom kunt u bij ons terecht voor het maken van al uw machine onderdelen.
                Zo kunt u voor onderhoud bij één partij terecht en blijft het onderhoud en controle proces overzichtelijk.
              </p>
              <p class="prose">
                We zijn gespecialiseerd in een groot aantal onderdelen voor uw machines. Of die onderdelen nu van RVS, Aluminium of staal zijn.
                De machine onderdelen, zoals frames en plaatwerk, maken wij voor machines in diverse branches.
              </p>
            </div>
          </div>
          <div class="relative mx-auto mt-12 max-w-prose text-base lg:mt-0 lg:max-w-none">
            <svg class="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
              <defs>
                <pattern id="bedc54bc-7371-44a2-a2bc-dc68d819ae60" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"/>
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"/>
            </svg>
            <section class="hidden lg:block relative bg-white rounded-sm shadow-lg">
              <div class="py-8 px-6 rounded-t-lg sm:px-10 sm:pt-10 sm:pb-8">
                <img class="w-9/12" src="../assets/metaalunie.svg" alt="Metaalunie" width="300" height="80">
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>

    <Tinybox
        v-model="index"
        :images="images"
        :loop="loopCheckbox"
        :no-thumbs="!thumbsCheckbox"
    ></Tinybox>

    <section class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-20">
      <ul role="list" class="grid grid-cols-2 md:grid-cols-4">
        <li v-for="(img, idx) in images" :key="idx" @click="index = idx" class="relative">
          <div class="block overflow-hidden w-full group aspect-w-10 aspect-h-7">
            <img :src="img.thumbnail" width="300" height="300" :alt="img.alt" class="object-cover pointer-events-none group-hover:opacity-75">
            <button type="button" class="absolute inset-0">
              <span class="sr-only">{{ img.caption }}</span>
            </button>
          </div>
        </li>
      </ul>
    </section>


    <div class="overflow-hidden py-16 px-4 bg-white xl:py-24 sm:px-6 lg:px-2">
      <div class="mx-auto max-w-max lg:max-w-7xl">
        <div class="relative z-10 mb-8 md:mb-2 md:px-6">
          <div class="max-w-prose text-base lg:max-w-none">
            <h2 class="mt-2 text-3xl font-semibold tracking-tight leading-8 text-gray-900 uppercase sm:text-xl">Constructiewerken van RVS, staal en aluminium</h2>
          </div>
        </div>
        <div class="relative">
          <svg class="hidden absolute top-0 right-0 -mt-20 -mr-20 md:block" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
            <defs>
              <pattern id="95e8f2de-6d30-4b7e-8159-f791729db21b" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"/>
              </pattern>
            </defs>
            <rect width="404" height="384" fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)"/>
          </svg>
          <svg class="hidden absolute bottom-0 left-0 -mb-20 -ml-20 md:block" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
            <defs>
              <pattern id="7a00fe67-0343-4a3c-8e81-c145097a3ce0" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"/>
              </pattern>
            </defs>
            <rect width="404" height="384" fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)"/>
          </svg>
          <div class="relative md:bg-white md:p-6">
            <div class="lg:grid lg:grid-cols-2 lg:gap-6">
              <div class="text-gray-600 prose prose-md lg:max-w-none ">
                <p>Naast ons specialisme in de installatie van leidingsystemen (staal en RVS) in de voedingsmiddelenindustrie vervaardigen wij ook licht constructiewerk in de bouw. Denk hierbij aan het vervaardigen van frames, bordessen, trappen en leuningen in staal en RVS.
                  Heeft u een eigen ontwerp? Ook dit rekenen we graag voor u na, om het vervolgens vakkundig te produceren.</p>
              </div>
              <div class="mt-6 text-gray-600 prose prose-indigo prose-md lg:mt-0 ">
                <p>Neem vrijblijvend contact met ons op om de mogelijkheden te bespreken. Wij bespreken vooraf met u technieken en werkwijze, zo houden we rekening met uw wensen en eisen. Hiermee bent u verzekerd van vakkundig advies en resultaat.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main>
</template>

<script>
import Tinybox from "vue-tinybox"

export default {
  name : "MachinesOnderdelen",

  components: {
    Tinybox,
  },

  data() {
    return {
      images         : [
        {
          src       : '/assets/img/mao1_img.webp',
          alt       : 'Image alttext',
          thumbnail : '/assets/img/mao1_thumb.webp',
          caption   : ''
        },
        {
          src       : '/assets/img/mao2_img.webp',
          alt       : 'Image alttext',
          thumbnail : '/assets/img/mao2_thumb.webp',
          caption   : ''
        },
        {
          src       : '/assets/img/mao3_img.webp',
          alt       : 'Image alttext',
          thumbnail : '/assets/img/mao3_thumb.webp',
          caption   : ''
        },
        {
          src       : '/assets/img/mao4_img.webp',
          alt       : 'Image alttext',
          thumbnail : '/assets/img/mao4_thumb.webp',
          caption   : ''
        }
      ],
      index          : null,
      loopCheckbox   : false,
      thumbsCheckbox : true
    };
  },

  metaInfo : {
    title : 'Machines & onderdelen',
    meta  : [
      {
        name    : 'description',
        content : 'C las en montagetechniek is gespecialiseerd in het monteren en vervaardigen van machines en onderdelen. Dit doen we geheel volgens uw wensen en specificaties.'
      }
    ]
  }

}
</script>

<style scoped>

</style>
